<template>
  <div class="no_touch">
    <div class="contr-content">
      <!--   运动计步   -->
      <van-tabs type="card" @click="onTabClick">
        <van-tab title="今天"></van-tab>
        <van-tab title="星期"></van-tab>
        <van-tab title="月份"></van-tab>
      </van-tabs>

      <div class="contr-date">
        <img class="img" @click="leftDay" src="../assets/app_arrow_left.png" alt=""/>
        <div @click="showCalendar" style="margin-left: 10px; margin-right: 10px">
          <div style="font-size: 20px">{{ getSwitchTitle() }}</div>
          <div>{{ getSwitchContent() }}</div>
        </div>
        <img class="img" @click="rightDay" src="../assets/app_arrow_right.png" alt=""/>
      </div>
      <bar-chart v-if="loaded" :chart-data="chartData" :options="chartOption"/>

      <div class="contr-step">
        <div class="contr-item">
          <img src="../assets/step_count_icon.png" alt=""/>
          <div class="text-value">
            <span>{{ Step }}</span>步
          </div>
        </div>
        <div class="contr-item">
          <img src="../assets/step_km_icon.png" alt=""/>
          <div class="text-value">
            <span>{{ Distance }}</span>公里
          </div>
        </div>
        <div class="contr-item">
          <img src="../assets/step_calories_icon.png" alt=""/>
          <div class="text-value">
            <span>{{ Cariello }}</span>卡路里
          </div>
        </div>
      </div>
    </div>

    <van-popup v-model="showPopup" position="bottom" :style="{ height: '40%' }">
      <van-datetime-picker
          v-model="currentDate"
          type="date"
          title="选择年月日"
          @confirm="confirmDate"
          :min-date="minDate"
          :max-date="maxDate"
      />
    </van-popup>
  </div>
</template>

<script>
import {post} from "@/router/httpUtils";
import BarChart from "@/components/BarChart";
import {APP_ID, Language, State_0, TimeOffset} from "@/constants/contants";
import {Toast} from "vant";
import Vue from 'vue';
import {Tab, Tabs} from 'vant';

Vue.use(Tab);
Vue.use(Tabs);


export default {
  name: "Step",
  components: {
    BarChart
  },
  data() {
    return {
      currentDate: new Date(),
      weekFirst: null,
      weekLast: null,
      monthFirst: null,
      monthLast: null,
      //0 day 1 week 2 month
      model: 0,
      Step: 0,
      Distance: 0.00,
      Cariello: 0.00,
      minDate: new Date(1900, 0, 1),
      maxDate: new Date(2100, 12, 31),
      showPopup: false,
      loaded: false,
      chartData: {
        labels: ['January', 'February'],
        datasets: [
          {
            label: 'Data One',
            backgroundColor: 'orange',
            data: [40, 20]
          }
        ]
      },
      chartOption: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }],
        },
      },
    }
  },
  mounted() {
    this.handleDate(this.currentDate)
  },
  methods: {
    showCalendar() {
      this.showPopup = true
    },
    leftDay() {
      let newDate
      if (this.model === 0) {
        newDate = this.currentDate.setDate(this.currentDate.getDate() - 1)
      } else if (this.model === 1) {
        newDate = this.currentDate.setDate(this.currentDate.getDate() - 7)
      } else {
        newDate = this.currentDate.setMonth(this.currentDate.getMonth() - 1)
      }
      this.currentDate = new Date(newDate)
      this.handleDate(this.currentDate)
    },
    rightDay() {
      let newDate
      if (this.model === 0) {
        newDate = this.currentDate.setDate(this.currentDate.getDate() + 1)
      } else if (this.model === 1) {
        newDate = this.currentDate.setDate(this.currentDate.getDate() + 7)
      } else {
        newDate = this.currentDate.setMonth(this.currentDate.getMonth() + 1)
      }
      this.currentDate = new Date(newDate)
      this.handleDate(this.currentDate)
    },
    confirmDate(date) {
      this.showPopup = false
      this.currentDate = date
      this.handleDate(date)
    },
    handleDate(date) {
      const newDate = new Date(date.valueOf())
      if (this.model === 0) {
        this.getSteps4Hour()
      } else if (this.model === 1) {
        // 本周一的日期
        newDate.setDate(newDate.getDate() - this.getChinaDay(newDate.getDay()) + 1);
        this.weekFirst = new Date(newDate.valueOf())
        console.log(this.weekFirst);
        const start = this.weekFirst.getFullYear() + '-' + (this.weekFirst.getMonth() + 1) + '-' + this.weekFirst.getDate() + ' 00:00:00'

        // 本周日的日期
        newDate.setDate(newDate.getDate() + 6);
        this.weekLast = newDate
        console.log(this.weekLast);
        const end = this.weekLast.getFullYear() + '-' + (this.weekLast.getMonth() + 1) + '-' + this.weekLast.getDate() + ' 23:59:59'
        this.getSteps4Day(start, end)
      } else {
        const monthY = newDate.getFullYear(), monthM = newDate.getMonth();
        this.monthFirst = new Date(monthY, monthM, 1);
        this.monthLast = new Date(monthY, monthM + 1, 0);
        console.log(this.monthFirst);
        console.log(this.monthLast);
        const start = this.monthFirst.getFullYear() + '-' + (this.monthFirst.getMonth() + 1) + '-' + this.monthFirst.getDate() + ' 00:00:00'
        const end = this.monthLast.getFullYear() + '-' + (this.monthLast.getMonth() + 1) + '-' + this.monthLast.getDate() + ' 23:59:59'
        this.getSteps4Day(start, end)
      }
    },
    getChinaDay(day) {
      return day === 0 ? 7 : day
    },
    getSteps4Hour() {
      const date = this.currentDate.getFullYear() + '-' + (this.currentDate.getMonth() + 1) + '-' + this.currentDate.getDate()

      post('/api/Health/GetStepsForHour', {
        "Date": date,
        "Imei": localStorage.imei,
        "TimeOffset": TimeOffset,
        "Token": localStorage.Token
      }).then(res => {
        console.log(res)

        if (res.State != State_0) {
          Toast.fail({
            message: '网络异常'
          })
          return
        }

        const labels = res.Items.map(i => {
          return i.Hour
        })
        const data = res.Items.map(i => {
          return i.Steps
        })
        this.chartData = {
          labels: labels,
          datasets: [{
            backgroundColor: 'orange',
            data: data
          }]
        }

        this.Step = res.Step
        this.Distance = res.Distance
        this.Cariello = res.Cariello
      }).then(() => {
        this.loaded = true
      })
    },
    getSteps4Day(start, end) {
      post('/api/Health/GetStepsForDay', {
        "AppId": APP_ID,
        "EndDate": end,
        "Imei": localStorage.imei,
        "Language": Language,
        "StartDate": start,
        "TimeOffset": TimeOffset,
        "Token": localStorage.Token
      }).then(res => {
        console.log(res)

        if (res.State != State_0) {
          Toast.fail({
            message: '网络异常'
          })
          return
        }

        let labels
        if (this.model === 1) {
          labels = ['周一', '周二', '周三', '周四', '周五', '周六', '周日']
        } else {
          labels = Array.from(new Array(res.Items.length).keys())
        }

        const data = res.Items.map(i => {
          return i.Steps
        })
        this.chartData = {
          labels: labels,
          datasets: [{
            backgroundColor: 'orange',
            data: data
          }]
        }

        this.Step = res.Step
        this.Distance = res.Distance
        this.Cariello = res.Cariello
      }).then(() => {
        this.loaded = true
      })
    },
    onTabClick(name) {
      this.model = name
      if (this.model === 0) {
       this.currentDate = new Date()
      } else if (this.model === 2) {
        this.currentDate = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), 1)
      }
      this.handleDate(this.currentDate)
    },
    getSwitchTitle() {
      if (this.model === 0) {
        return '星期' + '日一二三四五六'.charAt(this.currentDate.getDay())
      } else if (this.model === 1) {
        return '星期'
      } else {
        return '月份'
      }
    },
    getSwitchContent() {

      if (this.model === 0) {
        return this.currentDate.getFullYear() + '-' + (this.currentDate.getMonth() + 1) + '-' + this.currentDate.getDate()
      } else if (this.model === 1) {
        return this.currentDate.getFullYear() + ' ' + (this.currentDate.getMonth() + 1) + ' ' + this.weekFirst.getDate() + '-' + this.weekLast.getDate()
      } else {
        return this.monthFirst.getFullYear() + '-' + (this.monthFirst.getMonth() + 1)
      }
    }
  }
}
</script>

<style>
.van-tabs__nav {
  border: 1px solid #11B7F3;
}

.van-tab--active {
  background-color: #11B7F3 !important;
}

.van-tab {
  color: #11B7F3 !important;
  border-right: 1px solid #11B7F3 !important;
}

.van-tab--active {
  color: white !important;
}
</style>
<style scoped>
.contr-content {
  max-width: 600px;
  margin: 15px auto;
}

.contr-date {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
}

.img {
  width: 30px;
  height: 30px;
}

.contr-step {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.contr-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 33%;
}

.text-value {
  margin-top: 8px;
}
</style>